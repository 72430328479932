<template>
  <v-container class="main">
    <router-view />
  </v-container>
</template>

<script>

export default {
  name: "Advertising",
}
</script>

<style scoped>
.main{
  max-width: 1400px;
}
</style>